import {
  Box,
  Flex,
  HStack,
  Text,
  useDisclosure,
  VStack,
  AspectRatio,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { getLinks } from "../sitemap";
import { DrawerMenu } from "./drawer-menu";
import { MegaMenu } from "./mega-menu";
import { NavLink } from "./nav-link";
import { Global } from "./global";
import { Logo, RegionGray, HeaderBackgroundBig, ShopIcon } from "components";
import { Link } from "react-router-dom";
import AbiExpressModal from "components/abiexpress-modal";

export function Navbar({ hideAfter = false }) {
  const Links = getLinks();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLDivElement>(null);

  const {
    isOpen: isOpenGlobal,
    onOpen: onOpenGlobal,
    onClose: onCloseGlobal,
  } = useDisclosure();

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const [openedMenuIndex, setOpenedMenuIndex] = useState<number | null>(null);
  const openMenu = (index: number | null) => {
    if (openedMenuIndex === index) {
      setOpenedMenuIndex(null);
    } else {
      setOpenedMenuIndex(index);
    }
  };
  return (
    <Box bg="white" position="relative">
      <Box
        h={["120px", "120px", "180px", "220px", "220px"]}
        style={{
          position: "absolute",
          transition: "0.5s",
          top: "100%",
          width: "100%",
          marginTop: "-1rem",
          overflow: "hidden",
          zIndex: 1,
        }}
        display={hideAfter ? "none" : "block"}
      >
        <HeaderBackgroundBig
          style={{
            transition: "0.5s",
            height: "100%",
            width: "100%",
            marginTop: "-5%",
            opacity: hideAfter ? 0 : 1,
          }}
        />
      </Box>
      <VStack>
        <Flex
          w="100%"
          alignItems={"center"}
          justifyContent={"space-between"}
          px="18px"
          transition="0.5s"
        >
          <Link to="/">
            <HStack>
              <Box
                width={{ base: "100px", md: "140px", lg: "180px", xl: "230px" }}
                transition="0.5s"
              >
                <AspectRatio ratio={5 / 2.5}>
                  <Logo width="100%" />
                </AspectRatio>
              </Box>
              <Text
                marginLeft={{
                  base: "10px",
                  md: "15px",
                  lg: "15px",
                  xl: "20px",
                }}
                fontSize={{
                  base: "0.8rem",
                  md: "0.9rem",
                  lg: "1.0rem",
                  xl: "1.1rem",
                }}
                style={{
                  color: "#4d4d4d",
                  marginTop: "0.2rem",
                }}
              >
                MONGOLIA
              </Text>
            </HStack>
          </Link>
          <HStack
            spacing={8}
            alignItems={"center"}
            display={{ base: "none", md: "none", lg: "flex" }}
          >
            <HStack as={"nav"} spacing={2}>
              {Links.map((link, index) => {
                return (
                  <NavLink
                    action={() => (link.children ? openMenu(index) : null)}
                    href={link.children ? undefined : link.link}
                    key={`link-${index}`}
                  >
                    {link.title}
                  </NavLink>
                );
              })}
            </HStack>
          </HStack>
          <HStack
            spacing={3}
            display={{ base: "none", md: "none", lg: "flex" }}
            zIndex={2}
          >
            <VStack
              _hover={{ color: "#e65550", fill: "#AB7C94" }}
              spacing={0}
              onClick={() => {
                onOpenModal();
              }}
              cursor="pointer"
            >
              <ShopIcon color="#4d4d4d" width="28px" height="28px" />
              <Text fontSize="0.8rem">Сагс</Text>
            </VStack>
            <VStack
              _hover={{ color: "#e65550", fill: "#AB7C94" }}
              spacing={0}
              onClick={onOpenGlobal}
              cursor="pointer"
            >
              <RegionGray width="28px" height="28px" />
              <Text fontSize="0.8rem">Global</Text>
            </VStack>
          </HStack>
          <HStack
            display={{ base: "flex", md: "flex", lg: "none" }}
            p="10px"
            cursor="pointer"
            ref={btnRef}
            onClick={onOpen}
          >
            <Text
              fontSize="1rem"
              color="#e65550"
              fontWeight={600}
              paddingTop="3px"
            >
              MENU
            </Text>
            <Box
              style={{
                background: "#e65550",
                width: "26px",
                height: "22px",
                borderRadius: "52% 48% 63% 37% / 56% 34% 66% 44%",
              }}
            />
          </HStack>
        </Flex>
        {Links.map((link, index) => {
          if (link.children) {
            return (
              <MegaMenu
                megaLink={link}
                open={openedMenuIndex === index}
                close={() => openMenu(null)}
                key={index}
              />
            );
          }
          return null;
        })}
      </VStack>
      <DrawerMenu open={isOpen} close={onClose} btnRef={btnRef} />
      <Global isOpen={isOpenGlobal} onClose={onCloseGlobal} />
      <AbiExpressModal isOpen={isOpenModal} onClose={onCloseModal} />
    </Box>
  );
}
