import {
  AspectRatio,
  Box,
  Grid,
  GridItem,
  List,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  categoryMockData,
  CircleChevronRightIcon,
  MainContainer,
} from "components";
import { useState } from "react";
import { Link } from "react-router-dom";

const CategoryItem = ({
  category,
}: {
  category: {
    id: number;
    name: string;
    children: { id: number; name: string }[];
  };
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <GridItem
      cursor="pointer"
      onMouseOver={() => setFocused(true)}
      onMouseOut={() => setFocused(false)}
    >
      <AspectRatio ratio={1 / 1.1} border="solid 1px #EEE" borderRadius="4px">
        <VStack w="100%" h="100%" spacing={0}>
          <Box
            style={{
              flex: 1,
              width: "100%",
              position: "relative",
              background: `url(/assets/category/${category.id}.jpeg) center`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <Box
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                backgroundColor: "#f4f0ed",
                transition: "all .5s ease-out",
                opacity: focused ? 0.95 : 0,
              }}
            >
              <List spacing={3} p={4}>
                {category.children.map((sub) => (
                  <Link key={sub.id} to={`/category/${sub.id}`}>
                    <ListItem color="#4d4d4d" fontSize="14px">
                      <CircleChevronRightIcon
                        mr={1}
                        mb="5px"
                        w={4}
                        h={4}
                        color="#e65550"
                      />
                      {sub.name}
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Box>
          </Box>
          <Box w="100%" h="50px" p={1.5}>
            <Text color="#4d4d4d" fontSize="14px">
              {category.name}
              <CircleChevronRightIcon
                ml={2}
                mb="5px"
                w={4}
                h={4}
                color="#e65550"
              />
            </Text>
          </Box>
        </VStack>
      </AspectRatio>
    </GridItem>
  );
};

export function Category() {
  return (
    <MainContainer title="Бүтээгдэхүүн">
      <Box
        style={{
          width: "100%",
          background: `url(/assets/category-head.jpeg) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "0 30px",
        }}
      >
        <Text
          style={{
            color: "#4d4d4d",
            paddingTop: "310px",
            paddingBottom: "85px",
            fontSize: "2.5rem",
            fontWeight: "bold",
            maxWidth: "1130px",
            margin: "0 auto",
          }}
        >
          Бүтээгдэхүүн
        </Text>
      </Box>
      <Grid
        maxW="1130px"
        margin="0 auto"
        p={4}
        templateColumns={[
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
        ]}
        gap={4}
      >
        {categoryMockData.map((category, index) => (
          <CategoryItem key={index} category={category} />
        ))}
      </Grid>
    </MainContainer>
  );
}
