import { Box, ListItem, OrderedList, Text } from "@chakra-ui/react";
import { ContentTitle, ethicsMockData, PageContainer } from "components";

export const Ethics = () => (
  <PageContainer title="Пижион компанийн ёс зүй">
    <Text color="#4d4d4d" pb="70px">
      Нярай хүүхдийн хөхөөр хооллолт болон хүүхдийн өсөлтийн явцыг хагас зууны
      турш гүнзгий судалж, хүүхдэд хэрэгцээтэй олон төрлийн чанартай сайн
      бүтээгдэхүүн үйлдвэрлэн гаргадгаараа Пижион брэнд давуу
      талтай. Пижион брэнд нь аж үйлдвэрийн тэргүүлэх компани мөн
      тэргүүлэх брэнд гэж хүлээн зөвшөөрөгдсөн байна.
    </Text>
    <ContentTitle>Компанийн ёс зүйн удирдамж</ContentTitle>
    <Box padding="15px 25px" bgColor="#e5e5e5">
      <Text
        color="#4d4d4d"
        fontSize="1.2rem"
        fontWeight="500"
        textTransform="uppercase"
      >
        Доор дурдсан бүх дүрэм журмыг компанийн ажилчин нэг бүр өндөр итгэл
        үнэмшилтэйгээр даган мөрдөж үйл ажиллагаагаа явуулахыг та бүхэнд амлаж
        байна:
      </Text>
    </Box>
    <OrderedList color="#4d4d4d" padding="30px 25px" spacing={6}>
      {ethicsMockData.map((text, index) => (
        <ListItem key={index}>{text}</ListItem>
      ))}
    </OrderedList>
  </PageContainer>
);
