import api from "api";
import {
  CarouselAction,
  CarouselMenu,
  CarouselSwap,
  getLinks,
  MainContainer,
} from "components";
import { useEffect } from "react";

export function Welcome() {
  useEffect(() => {
    api.auth.health();
  }, []);

  return (
    <MainContainer>
      <CarouselSwap />
      <CarouselAction />
      <CarouselMenu menus={getLinks()} />
    </MainContainer>
  );
}
