import { Box, Grid, GridItem, HStack, Text, VStack } from "@chakra-ui/react";
import { CircleChevronRightIcon } from "components";
import { useState } from "react";
import { Link } from "react-router-dom";

const MenuItem = ({
  image,
  text,
  color,
  url,
}: {
  image: string;
  text: string;
  color: string;
  url: string;
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <GridItem
      position="relative"
      cursor="pointer"
      h={["calc(40vw)", "calc(40vw)", "calc(30vw)", "calc(20vw)"]}
      onMouseOver={() => setFocused(true)}
      onMouseOut={() => setFocused(false)}
    >
      <Box
        style={{
          top: "50%",
          left: "50%",
          position: "absolute",
          height: focused ? "70%" : "100%",
          width: focused ? "40%" : "100%",
          transform: "translate3d(-50%, -50%, 0)",
          background: `url(${image}) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          transition: "all .5s ease-out",
          borderRadius: focused
            ? "41% 59% 37% 63% / 43% 43% 57% 57%"
            : "0% 0% 0% 0%",
          overflow: "hidden",
        }}
      >
        <Box
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: color,
            transition: "all .5s ease-out",
            opacity: focused ? 1 : 0,
          }}
        ></Box>
        <Box
          style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            backgroundColor: "black",
            opacity: focused ? 0 : 0.3,
          }}
        ></Box>
      </Box>
      <Link to={`${url}`}>
        <HStack
          style={{
            top: "50%",
            left: "50%",
            position: "absolute",
            transform: "translate3d(-50%, -50%, 0)",
          }}
        >
          <VStack>
            <Text
              fontSize={["1.2rem", "1.3rem", "1.5rem"]}
              style={{
                color: "white",
                marginBottom: "1rem",
                textAlign: "center",
              }}
            >
              {text}
            </Text>
            <CircleChevronRightIcon color="white" fontSize={15} />
          </VStack>
        </HStack>
      </Link>
    </GridItem>
  );
};

export function CarouselMenu({ menus, prefix = "" }: any) {
  const colors = ["#e65550", "#c2bfbd", "#f4cf41", "#B4D2DC"];
  let ii = 0;
  return (
    <Grid
      templateColumns={["repeat(2, 1fr)", "repeat(2, 1fr)", "repeat(2, 1fr)"]}
    >
      {menus.map((menu: any, index: number) => {
        if (menu.image) {
          ii++;
          return (
            <MenuItem
              key={index}
              image={menu.image}
              text={menu.title}
              color={colors[(ii - 1) % 4]}
              url={`${prefix}${menu.link}`}
            />
          );
        }
        return null;
      })}
    </Grid>
  );
}
