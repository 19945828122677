import { Flex, Image, Text, VStack } from "@chakra-ui/react";
import {
  abicoMockData,
  AboutTable,
  ContentTitle,
  PageContainer,
} from "components";

export const Abico = () => (
  <PageContainer title="Монгол дахь албан ёсны борлуулагч Абико ХХК">
    <ContentTitle>Хэрэглэгчдийнхээ сэтгэл ханамжийн төлөө</ContentTitle>
    <Flex flexDir={["column", "column", "row"]}>
      <Text color="#4d4d4d" pr="30px">
        Абико компани нь 2012 оноос худалдаа, үйлчилгээний чиглэлээр үйл
        ажиллагаагаа явуулж байна. Япон улсын өндөр чанартай бүтээгдэхүүн
        үйлчилгээг хэрэглэгчиддээ хүргэхийг зорин ажилласны дүнд Японы салбартаа
        тэргүүлэгч компаниуд болох Pigeon корпораци, Kagome корпораци, Daio
        paper корпораци, S&B компани, PiP компани, Dydo компани, Fundokin
        компаниудын Монгол дахь албан ёсны борлуулагчаар амжилттай ажиллаж
        байна. Мөн Абико компанийн хөрөнгө оруулалттай Митс Жапан ХХК нь хамгийн
        өргөн сонголттой Япон гэр ахуйн барааны салбар дэлгүүрүүдийг нээн
        ажиллуулж байна.
      </Text>
      <Image
        width="300px"
        objectFit="contain"
        src="/assets/abico.jpg"
        alt="Pigeon Logo"
        mb="30px"
      />
    </Flex>
    <VStack spacing={6} pb="60px">
      <Text color="#4d4d4d">
        Бидний хувьд бизнесийн өндөр ёс зүйг баримтлан ажилладаг ба харилцагч
        компаниудынхаа брендийн концепт, чанар, имейжийг Монголын хэрэглэгчиддээ
        таниулах ойлгуулах чиглэлээр чармайн ажилладаг.
      </Text>
      <Text color="#4d4d4d">
        Өндөр чанартай бүтээгдэхүүнийг зөвхөн борлуулаад зогсохгүй эрүүл мэнд,
        хүүхэд бойжуулах талаар үнэ төлбөргүй сургалт зөвлөгөөг тогтмол хийдэг
        нь бидний онцлог юм. Тухайлбал: Эцэг эхийн хайрнаас соёолон ургасан үр
        хүүхдээ хэрхэн эрүүл саруул энх тунх өсгөн бойжуулах талаар “Бидний хайр
        таны хүүхдэд” уриатай Пижион компанийн үзэл санааг манай хамт олон
        бусдад түгээх , таниулах , сурган хэрэгжүүлэх үүднээс тэдний арга
        туршлагыг байнга бусдадтай хуваалцсаар байна.
      </Text>
      <Text color="#4d4d4d">
        Мөн байгаль, нийгэм , хувь хүмүүсийн сайхан амьдралын төлөөх сайн үйлсэд
        хувь нэмрээ оруулах үзэл санаагаар жигүүрлэсэн “Кагоме” брендийн
        бүтээгдэхүүнээр дамжуулан Япон улсын хүн амын эрүүл мэнд, урт нас ,
        сайхан амьдралд оруулсан тэдний хувь нэмрийг ч бид Монголын ард түмэндээ
        мэдрүүлэн хүртээхээр “Амьд байгалийн амт шим, ач тус” гэсэн уриа
        удирдамжтайгаар ажиллаж байна.
      </Text>
    </VStack>
    <AboutTable data={abicoMockData} />
  </PageContainer>
);
