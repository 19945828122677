import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { CircleChevronRightIcon } from "components";
import { useState } from "react";
import { Link } from "react-router-dom";

export function CarouselAction() {
  const [focused, setFocused] = useState(0);
  const [buttonFocused, setButtonFocused] = useState(false);

  const data = [
    {
      image: "/assets/welcome/5.jpg",
      color: "#e65550",
      shape: "41% 59% 37% 63% / 43% 43% 57% 57%",
      text: "Хүүхдэд ээлтэй ирээдүйн төлөө",
    },
    {
      image: "/assets/welcome/3.jpg",
      color: "#c2bfbd",
      shape: "44% 56% 33% 67% / 44% 54% 46% 56%",
      text: "Дутуу төрсөн нярай бүрийн төлөө",
    },
    {
      image: "/assets/welcome/4.jpg",
      color: "#f4cf41",
      shape: "68% 32% 62% 38% / 44% 38% 62% 56%",
      text: "Тусгай асаргаа шаардлагатай хүүхдүүдийн төлөө",
    },
  ];
  return (
    <Box>
      <Box
        height={["calc(80vw)", "calc(80vw)", "calc(50vw)", "calc(40vw)"]}
        style={{ position: "relative", width: "100%" }}
      >
        {data.map((value, index) => {
          return (
            <Box
              key={index}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `url(${value.image}) center`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                opacity: focused === index ? 1 : 0,
                transition: "0.3s",
              }}
            ></Box>
          );
        })}

        <Flex
          bottom={["90px", "90px", "190px", "190px"]}
          direction={["column", "column", "row", "row"]}
          style={{
            position: "absolute",
            right: "80px",
            transition: "all 0.3s ease",
          }}
        >
          {data.map((value, index) => {
            return (
              <VStack
                key={index}
                width={["150px", "150px", "150px", "200px"]}
                height={["120px", "120px", "120px", "160px"]}
                padding={["23px", "23px", "23px", "35px"]}
                style={{
                  background: value.color,
                  borderRadius: value.shape,
                  marginRight: 40,
                  marginBottom: 20,
                  transform: `scale(${focused === index ? 1.2 : 1})`,
                  transitionDuration: "0.3s, 0.3s",
                }}
                onMouseOver={() => setFocused(index)}
              >
                <Text
                  fontSize={["0.8rem", "0.8rem", "0.8rem", "1.0rem"]}
                  style={{
                    color: "white",
                    textAlign: "center",
                    fontWeight: 700,
                  }}
                >
                  {value.text}
                </Text>
                {/* <CircleChevronRightIcon color="white" fontSize={15} /> */}
              </VStack>
            );
          })}
        </Flex>
        <Link to="/news">
          <HStack
            bottom={["35px", "35px", "135px", "135px"]}
            style={{
              position: "absolute",
              right: "18px",
              background: "#fff",
              borderRadius: "32% 68% 37% 63% / 38% 47% 53% 62%",
              transition: "all 0.3s ease",
              transform: `scale(${buttonFocused ? 1.2 : 1})`,
              padding: "25px 15px",
            }}
            onMouseOver={() => setButtonFocused(true)}
            onMouseOut={() => setButtonFocused(false)}
          >
            <Text
              style={{
                color: "#e65550",
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Дэлгэрэнгүй
            </Text>
            <CircleChevronRightIcon color="#e65550" fontSize={12} />
          </HStack>
        </Link>
        <Box
          display={["none", "none", "block", "block"]}
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(230, 85, 80, 0.7)",
            padding: "15px 30px",
            transition: "all 0.3s ease",
          }}
        >
          <Text color="white" fontSize="3rem">
            Хүүхдэд ээлтэй дэлхийг
          </Text>
          <Text color="white" fontSize="1.2rem" ml="5px" mt="-1rem">
            бий болгохоор хийгдэж буй ажлууд
          </Text>
        </Box>
      </Box>
      <Box
        display={["block", "block", "none", "none"]}
        style={{
          background: "#eeefef",
          padding: "30px",
        }}
      >
        <Text color="#e65550" fontSize="3rem">
          Celebrate Babies in Action
        </Text>
        <Text color="#e65550" fontSize="1.2rem" ml="5px" mt="-1rem">
          Our actions to make this world more baby-friendly
        </Text>
      </Box>
    </Box>
  );
}
