import { Box, Image, Text, VStack } from "@chakra-ui/react";
import api from "api";
import { advicesMockData, MainContainer } from "components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function Advice() {
  let { id } = useParams();

  useEffect(() => {
    api.auth.health();
  }, []);

  const [advice, setAdvice] = useState<{
    id_article: number;
    id_article_category: number;
    id_age: number;
    title: any;
    youtube_url: string;
    description: string;
    image: string;
  } | null>(null);

  useEffect(() => {
    if (id) {
      advicesMockData.forEach((a) => {
        if (a.id_article.toString() === id) {
          setAdvice(a);
        }
      });
    }
  }, [id]);

  return (
    <MainContainer title="Зөвлөгөө">
      <Box
        style={{
          width: "100%",
          background: `url(/assets/category-head.jpeg) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "0 30px",
        }}
      >
        <Text
          style={{
            color: "#4d4d4d",
            paddingTop: "310px",
            paddingBottom: "85px",
            fontSize: "2.5rem",
            fontWeight: "bold",
            maxWidth: "1130px",
            margin: "0 auto",
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: advice?.title! }} />
        </Text>
      </Box>
      <VStack maxW="1130px" margin="0 auto" p={4}>
        <Image src={`/assets/advice/content/${advice?.image}`} width="100%" />
        <div dangerouslySetInnerHTML={{ __html: advice?.description! }} />
      </VStack>
    </MainContainer>
  );
}
