import { Flex, Image, Text, VStack } from "@chakra-ui/react";
import { ContentTitle, PageContainer } from "components";

export const Logo = () => (
  <PageContainer title="Пижион нэр, логоний гарал үүсэл">
    <ContentTitle>Компанийн нэрний гарал үүсэл</ContentTitle>
    <Flex flexDir={["column", "column", "row"]}>
      <VStack>
        <Text color="#4d4d4d">
          Анх 1951 онд Энх тайвны бэлэг тэмдэг болгож “Тагтаа” гэсэн утгатай
          энэхүү нэрийг компанидаа өгөхдөө ХҮҮХЭД бүрт аз жаргалыг хүсэж, даян
          дэлхийд энх тайвныг түгээсэн компани болохыг бэлгэдсэн байдаг.
        </Text>
        <Text color="#4d4d4d">
          Өнөөдөр ч гэсэн энэхүү хүсэл, зорилгоо хадгалсаар байгаа бөгөөд
          ирээдүйд ч хувирахгүй гэдэгт эргэлзэхгүй байна.
        </Text>
      </VStack>
      <Image
        width="300px"
        objectFit="contain"
        src="/assets/about/origin01.png"
        alt="Pigeon Logo"
        mb="40px"
      />
    </Flex>
    <ContentTitle>Пижион брэндийн логоны зүрх</ContentTitle>
    <Flex flexDir={["column", "column", "row"]}>
      <VStack>
        <Text color="#4d4d4d">
          “PIGEON”/ Пижион / гэсэн үгний “P” үсгийг сайтар анзаарч харвал энэхүү
          үсэг нь том зүрхэнд багтсан жижиг зүрхийг дүрсэлсэн байдаг.
          Пижионы лого нь Ээж болон хэвлий дэх ургийн зүрхийг бэлгэдсэн хайрын
          бэлэг тэмдэг юм. Мөн Пижионы зүгээс энэ дэлхийн бүх ээж, хүүхэд, гэр
          бүлд бэлэглэж буй хайрыг илэрхийлдэг.
        </Text>
        <Text color="#4d4d4d">
          Ээж, аавуудыг орлож чадахгүй ч олон олон ээж, аав, хүүхдэд хайрыг
          түгээхийг хүсдэг. Энэ бол Пижионы үүрэг. Энэ бол Пижионы эрхэм хүсэл.
        </Text>
      </VStack>
      <Image
        width="300px"
        objectFit="contain"
        src="/assets/about/p_origin_01.jpeg"
        alt="Pigeon Logo"
      />
    </Flex>
  </PageContainer>
);

export default Logo;
