import axiosClient from "./axios";

const ROUTE_PREFIX = "news";

const news = {
  get: (id: string) => axiosClient.get(`${ROUTE_PREFIX}?news=${id}`),
  all: () => axiosClient.get(ROUTE_PREFIX),
};

export default news;
