import axios from "axios";

export const BASE_API_URL = "https://api.pigeon.mn/";

const token = localStorage.getItem("serviceToken");

const axiosClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    authorizationToken: `${token}`,
  },
});

export default axiosClient;
