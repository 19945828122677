import {
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { globalLinks } from "components/mock-datas";

export const Global = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: any;
}) => (
  <Modal isOpen={isOpen} onClose={onClose} size="2xl">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Улсууд</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Grid templateColumns="repeat(3, 1fr)" gap={2} paddingBottom={4}>
          {globalLinks.map((global, index) => (
            <GridItem key={`global-${index}`}>
              <a href={global.url} target="_blank" rel="noreferrer">
                <HStack>
                  <Image src={`/assets/global/${global.image}`} />{" "}
                  <Text fontSize="14px">{global.title}</Text>
                </HStack>
              </a>
            </GridItem>
          ))}
        </Grid>
      </ModalBody>
    </ModalContent>
  </Modal>
);
