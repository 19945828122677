import { Box, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import {
  adviceCategoryMockData,
  advicesMockData,
  CircleChevronRightIcon,
  MainContainer,
} from "components";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

const AdviceItem = ({
  advice,
}: {
  advice: {
    id_article: number;
    id_article_category: number;
    id_age: number;
    title: any;
    youtube_url: string;
    description: string;
    image: string;
  };
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <GridItem
      cursor="pointer"
      onMouseOver={() => setFocused(true)}
      onMouseOut={() => setFocused(false)}
    >
      <Link to={`/advice/${advice.id_article}`}>
        <VStack alignItems="flex-start">
          <Box
            height={["50vw", "50vw", "25vw", "20vw", "15vw"]}
            style={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                background: `url(/assets/advice/content/${advice.image}) center`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                transition: "all .5s ease-out",
                transform: focused ? "scale(1.1)" : "scale(1.0)",
              }}
            ></Box>
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                height: "4px",
                width: "100%",
                backgroundColor: "#e65550",
                transition: "all .5s ease-out",
                opacity: focused ? 1 : 0,
              }}
            ></Box>
          </Box>
          <Text color="#4d4d4d" fontSize="1.2rem" fontWeight="500">
            {advice.title}
            <CircleChevronRightIcon
              ml={2}
              mb="5px"
              w={4}
              h={4}
              color="#e65550"
            />
          </Text>
        </VStack>
      </Link>
    </GridItem>
  );
};

export function AdviceCategory() {
  let { id } = useParams();

  const [advice, setAdviceCategory] = useState<{
    id: number;
    title: string;
  } | null>(null);
  console.log(id);
  const [advices, setAdvices] = useState<
    {
      id_article: number;
      id_article_category: number;
      id_age: number;
      title: any;
      youtube_url: string;
      description: string;
      image: string;
    }[]
  >([]);

  useEffect(() => {
    if (id) {
      adviceCategoryMockData.forEach((parent) => {
        parent.children.forEach((child) => {
          if (child.id.toString() === id) {
            setAdviceCategory(child);
          }
        });
      });
      const tempAdvices: {
        id_article: number;
        id_article_category: number;
        id_age: number;
        title: string;
        youtube_url: string;
        description: string;
        image: string;
      }[] = [];
      advicesMockData.forEach((advice) => {
        if (advice.id_article_category.toString() === id) {
          tempAdvices.push(advice);
        }
      });
      setAdvices(tempAdvices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <MainContainer title="Зөвлөгөө">
      <Box
        style={{
          width: "100%",
          background: `url(/assets/category-head.jpeg) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "0 30px",
        }}
      >
        <Text
          style={{
            color: "#4d4d4d",
            paddingTop: "310px",
            paddingBottom: "85px",
            fontSize: "2.5rem",
            fontWeight: "bold",
            maxWidth: "1130px",
            margin: "0 auto",
          }}
        >
          {advice?.title}
        </Text>
      </Box>
      <Grid
        maxW="1130px"
        margin="0 auto"
        p={4}
        templateColumns={[
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
        ]}
        gap={4}
      >
        {advices.map((advice, index) => (
          <AdviceItem advice={advice} key={index} />
        ))}
      </Grid>
    </MainContainer>
  );
}
