import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export const NavLink = ({
  children,
  action,
  href,
}: {
  children: ReactNode;
  action?: Function;
  href?: string;
}) => (
  <Box
    px={2}
    py={1}
    color="#666"
    fontWeight={400}
    _hover={{
      borderBottom: "3px solid #e65550",
      marginBottom: "-3px",
    }}
    cursor="pointer"
    transition="0.3s"
    fontSize="1rem"
    onClick={() => (action ? action() : null)}
  >
    {href ? <Link to={href}>{children}</Link> : children}
  </Box>
);
