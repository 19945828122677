import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import {
  CategoryProductItem,
  CircleChevronRightIcon,
  MainContainer,
  productMockData,
  ShopIcon,
} from "components";
import AbiExpressModal from "components/abiexpress-modal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function QuestionAnswers({ qa }: { qa: { title: string; desc: string } }) {
  const { getDisclosureProps, getButtonProps } = useDisclosure();

  const buttonProps = getButtonProps();
  const disclosureProps = getDisclosureProps();
  return (
    <VStack
      style={{
        width: "100%",
        border: "solid 3px #FFEBE8",
        borderRadius: "5px",
        alignItems: "flex-start",
        color: "#444",
        fontSize: "14px",
        padding: "5px 10px",
      }}
    >
      <HStack {...buttonProps} cursor="pointer">
        <Text
          style={{ color: "#fd999a", fontSize: "24px", fontWeight: "bold" }}
        >
          Q
        </Text>
        <Text p="5px">{qa.title}</Text>
      </HStack>
      <VStack {...disclosureProps}>
        <Box
          style={{
            border: "1px #D4D4D4",
            borderStyle: "dashed none none none",
            width: "100%",
          }}
        ></Box>
        <HStack {...buttonProps} cursor="pointer" alignItems="flex-start">
          <Text
            style={{ color: "#fabc5f", fontSize: "24px", fontWeight: "bold" }}
          >
            A
          </Text>
          <div dangerouslySetInnerHTML={{ __html: qa.desc }} />
        </HStack>
      </VStack>
    </VStack>
  );
}

export function Product() {
  let { id } = useParams();

  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const [product, setProduct] = useState<
    | {
        id: string;
        category: string;
        title: string;
        description: string;
        price: number;
        thumb: string;
        age: string;
        faq: { title: string; desc: string }[];
        feature: { title: string; desc: string }[];
        images: string[];
      }
    | undefined
  >(undefined);

  const [categoryProducts, setCategoryProducts] = useState<
    {
      id: string;
      thumb: string;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    if (id) {
      setProduct(productMockData.find((p) => p.id === id));
    }
  }, [id]);

  useEffect(() => {
    if (product) {
      if (product.images.length > 0) {
        setSelectedImage(product?.images[0]);
      }
      setCategoryProducts(
        productMockData.filter((p) => p.category === product.category)
      );
    }
  }, [product]);

  return (
    <MainContainer title="Бүтээгдэхүүн" hideNavbarAfter={true}>
      <VStack alignItems="flex-start" maxW="1130px" margin="0 auto" p={4}>
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            "repeat(1, 1fr)",
            "repeat(2, 1fr)",
            "repeat(2, 1fr)",
          ]}
          gap={4}
          width="100%"
        >
          <GridItem>
            <VStack spacing={2}>
              <Image
                width="100%"
                objectFit="contain"
                src={`/uploads/products/${selectedImage}`}
              />
              <HStack overflow="scroll">
                {product?.images.map((i, index) => (
                  <Image
                    key={`image-${index}`}
                    width="80px"
                    height="80px"
                    objectFit="contain"
                    src={`/uploads/products/${i}`}
                    style={{
                      border:
                        selectedImage === i
                          ? "solid 3px #F00"
                          : "solid 1px #EEE",
                    }}
                    onClick={() => setSelectedImage(i)}
                  />
                ))}
              </HStack>
              <VStack spacing={0} width="100%">
                <HStack
                  justify="space-between"
                  style={{
                    width: "100%",
                    padding: "15px 20px",
                    borderRadius: "5px 5px 0 0",
                    background: "#F9F4EC",
                    marginTop: "10px",
                  }}
                >
                  <Text fontSize="18px">Төстэй бүтээгдэхүүн</Text>
                </HStack>
                <VStack
                  style={{
                    width: "100%",
                    padding: "5px",
                    border: "1px #EEE",
                    borderStyle: "none solid solid",
                    borderRadius: "0 0 5px 5px",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid
                    templateColumns={["repeat(3, 1fr)"]}
                    width="100%"
                    gap={2}
                    p={1}
                  >
                    {categoryProducts.map((p, index) => (
                      <CategoryProductItem
                        product={p}
                        key={`category-${index}`}
                      />
                    ))}
                  </Grid>
                </VStack>
              </VStack>
            </VStack>
          </GridItem>
          <GridItem>
            <HStack
              justify="space-between"
              style={{
                width: "100%",
                padding: "15px 20px",
                borderRadius: "5px 5px 0 0",
                background: "#F9F4EC",
                lineHeight: "1.4",
              }}
            >
              <Text fontSize="18px" fontWeight="bold">
                {product?.title}
              </Text>
              <Text
                style={{
                  padding: "8px",
                  borderRadius: "4px",
                  background: "#F8ABAD",
                  color: "#FFF",
                  fontWeight: "bold",
                }}
              >
                {product?.age}
              </Text>
            </HStack>
            <VStack
              style={{
                width: "100%",
                padding: "5px",
                border: "1px #EEE",
                borderStyle: "none solid solid",
                borderRadius: "0 0 5px 5px",
                alignItems: "flex-start",
              }}
            >
              <HStack justifyContent="space-between" w="full" paddingX="10px">
                <Text fontSize="18px" fontWeight="bold" padding={2}>
                  {product?.price
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  ₮
                </Text>
                <Button
                  colorScheme="teal"
                  variant="solid"
                  onClick={() => onOpenModal()}
                >
                  <ShopIcon color="white" width="20px" height="20px" mr={2} />
                  Сагс
                </Button>
              </HStack>
              <Box
                style={{
                  border: "1px #D4D4D4",
                  borderStyle: "dashed none none none",
                  width: "100%",
                }}
              ></Box>
              <Table variant="unstyled" fontSize="14px">
                <Tbody>
                  {product?.feature.map((f, index) => (
                    <Tr key={`feature-${index}`}>
                      <Td fontWeight="bold">{f.title}</Td>
                      <Td>{f.desc}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </VStack>
            <VStack alignItems="flex-start" fontSize="14px" padding="20px 5px">
              <div
                dangerouslySetInnerHTML={{ __html: product?.description! }}
              />
            </VStack>
          </GridItem>
        </Grid>
        {product?.faq && (
          <VStack pt={4} width="100%">
            <Text fontSize="18px" fontWeight="bold">
              <CircleChevronRightIcon
                mr={2}
                mb="5px"
                w={4}
                h={4}
                color="#e65550"
              />
              Асуулт & Хариулт
            </Text>
            {product?.faq.map((f, index) => (
              <QuestionAnswers key={`qa-${index}`} qa={f} />
            ))}
          </VStack>
        )}
      </VStack>
      <AbiExpressModal isOpen={isOpenModal} onClose={onCloseModal} />
    </MainContainer>
  );
}
