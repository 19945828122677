import { Box, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import api from "api";
import { MainContainer } from "components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NewsItem = ({
  news,
}: {
  news: {
    category: string;
    date_created: string;
    news_id: string;
    image: string;
    title: string;
  };
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <GridItem
      cursor="pointer"
      onMouseOver={() => setFocused(true)}
      onMouseOut={() => setFocused(false)}
    >
      <Link to={`/news/${news.news_id}`}>
        <VStack alignItems="flex-start">
          <Box
            height={["50vw", "50vw", "25vw", "20vw", "15vw"]}
            style={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              style={{
                position: "absolute",
                top: 15,
                height: "100%",
                width: "100%",
                background: `url(/uploads/news/${news.image}) center`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                transition: "all .5s ease-out",
                transform: focused ? "scale(1.1)" : "scale(1.0)",
              }}
            ></Box>
            <Text
              style={{
                position: "absolute",
                padding: "5px 15px",
                backgroundColor: "#e65550",
                color: "white",
                fontWeight: "bold",
                fontSize: "14px",
              }}
            >
              {news.category}
            </Text>
            <Box
              style={{
                position: "absolute",
                bottom: 0,
                height: "4px",
                width: "100%",
                backgroundColor: "#e65550",
                transition: "all .5s ease-out",
                opacity: focused ? 1 : 0,
              }}
            ></Box>
          </Box>
          <Text color="#4d4d4d" fontSize="12px">
            {news.date_created}
          </Text>
          <Text color="#4d4d4d" fontSize="1.2rem" fontWeight="500">
            {news.title}
          </Text>
        </VStack>
      </Link>
    </GridItem>
  );
};

export function News() {
  const [news, setNews] = useState<
    {
      category: string;
      date_created: string;
      news_id: string;
      image: string;
      title: string;
    }[]
  >([]);

  useEffect(() => {
    api.news.all().then((result) => {
      const tempNews: {
        category: string;
        date_created: string;
        news_id: string;
        image: string;
        title: string;
      }[] = result.data;
      if (tempNews.length > 0) {
        tempNews.sort((a, b) =>
          a.category < b.category ? 1 : b.category < a.category ? -1 : 0
        );
        setNews(tempNews);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainContainer title="Мэдээ, мэдээлэл">
      <Box
        style={{
          width: "100%",
          background: `url(/assets/news-head.jpeg) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "0 30px",
        }}
      >
        <Text
          style={{
            color: "#4d4d4d",
            paddingTop: "310px",
            paddingBottom: "85px",
            fontSize: "2.5rem",
            fontWeight: "bold",
            maxWidth: "1130px",
            margin: "0 auto",
          }}
        >
          Мэдээ, мэдээлэл
        </Text>
      </Box>
      <Grid
        maxW="1130px"
        margin="0 auto"
        p={8}
        templateColumns={[
          "repeat(1, 1fr)",
          "repeat(1, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
        ]}
        gap={8}
      >
        {news.map((n) => (
          <NewsItem key={n.news_id} news={n} />
        ))}
      </Grid>
    </MainContainer>
  );
}
