import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

const AbiExpressModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: Function;
}) => (
  <Modal closeOnOverlayClick={true} isOpen={isOpen} onClose={() => onClose()}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Бүтээгдэхүүн захиалага</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={6}>
        Пижион брендийн бүтээгдэхүүн захиалга нь Абико компанийн{" "}
        <Link
          to="https://abiexpress.mn/products/10398"
          target="_blank"
          style={{ fontWeight: "bold" }}
        >
          AbiExpress
        </Link>{" "}
        вэбсайтаар хийгддэг болсон тул за захиалгаа{" "}
        <Link
          to="https://abiexpress.mn/products/10398"
          target="_blank"
          style={{ fontWeight: "bold" }}
        >
          https://abiexpress.mn
        </Link>{" "}
        сайтаар үргэлжлүүлнэ үү.
      </ModalBody>
      <ModalFooter>
        <Button
          backgroundColor="#e65550"
          color="white"
          mr={3}
          onClick={() =>
            window.open("https://abiexpress.mn/products/10398", "_blank")
          }
        >
          AbiExpress сайтаар зочлох
        </Button>
        <Button onClick={() => onClose()}>Хаах</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default AbiExpressModal;
