import { Flex, Text } from "@chakra-ui/react";
import { useState } from "react";

const CarouselItem = ({
  url,
  title,
  description,
}: {
  url: string;
  title: string;
  description: string;
}) => {
  const [isFocus, setFocus] = useState(false);

  const absoluteFull: any = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  };
  return (
    <div
      style={{
        flex: isFocus ? 3 : 1,
        height: "100%",
        transition: "0.3s",
        position: "relative",
      }}
      onMouseOver={() => setFocus(true)}
      onMouseOut={() => setFocus(false)}
    >
      <div
        style={{
          ...absoluteFull,
          background: `url(${url}) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          transition: "0.4s",
          filter: `brightness(${!isFocus ? 100 : 50}%)`,
        }}
      ></div>
      <Flex
        style={{
          ...absoluteFull,
          justifyContent: "center",
          opacity: isFocus ? 0 : 1,
          transition: "0.4s",
          transitionDelay: isFocus ? "0s" : "0.3s",
        }}
        direction="column"
      >
        <Text
          style={{
            color: "white",
            fontSize: "3rem",
            textAlign: "center",
          }}
        >
          {title}
        </Text>
      </Flex>
      <Flex
        style={{
          ...absoluteFull,
          justifyContent: "center",
          padding: "10%",
          transition: isFocus ? "0.3s" : "0.1s",
          opacity: isFocus ? 1 : 0,
          transitionDelay: isFocus ? "0.4s" : "0s",
        }}
        direction="column"
      >
        <Text
          style={{
            color: "white",
            fontSize: "3rem",
            textAlign: "left",
          }}
        >
          {title}
        </Text>
        <Text
          style={{
            color: "white",
            fontSize: "1.2 rem",
            textAlign: "left",
            marginTop: "20px",
          }}
        >
          {description}
        </Text>
      </Flex>
    </div>
  );
};

export function CarouselSwap() {
  return (
    <Flex direction={"column"}>
      <Flex
        h={["calc(120vh)", "calc(120vh)", "calc(150vh)", "calc(70vh)"]}
        direction={["column", "column", "column", "row"]}
      >
        <Flex
          flex={[4, 4, 4, 0]}
          style={{
            width: "100%",
            height: "200px",
            background: `url(/assets/welcome/p_opening_en_sp.jpeg) center`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></Flex>
        <CarouselItem
          url="/assets/welcome/p_mainv_07.jpg"
          title="ЗОРИЛГО"
          description="We want to make the world more baby-friendly by furthering our commitment to understanding and addressing babies’ unique needs"
        />
        <CarouselItem
          url="/assets/welcome/p_mainv_08.jpg"
          title="АМЛАЛТ"
          description="Celebrate babies the way they are" // Бяцхан зүрх бүхний төлөө
        />
        <CarouselItem
          url="/assets/welcome/p_mainv_09.jpg"
          title="ТҮҮХ"
          description="Every baby is born with a remarkable gift: the ability to be truly themselves. Pigeon believes in preserving and nurturing the qualities that make each baby special. Our years of experience in serving generations of babies has taught us there’s no one-size-fits-all approach to development. Each baby deserves to progress at their own pace and to become whoever they want to be. We strive to encourage curiosity, celebrate diversity, and open horizons, because a society that treasures these things is one that will grow up to be creative, inclusive, and free."
        />
      </Flex>
      <Flex
        bgColor="#e2d8d3"
        padding="30px"
        display={["none", "none", "block", "block"]}
      >
        <Text color="#e65550" fontSize="3.0rem" textAlign="center">
          Бяцхан зүрх бүхний төлөө
        </Text>
      </Flex>
    </Flex>
  );
}
