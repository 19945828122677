import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CircleChevronRightIcon } from "components";
import { useState } from "react";
import { Link } from "react-router-dom";

const MegaMenuItem = ({ megaLink, sublink }: any) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <GridItem
      cursor="pointer"
      onMouseOver={() => setIsFocus(true)}
      onMouseOut={() => setIsFocus(false)}
    >
      <Link to={`${megaLink.link}${sublink.link}`}>
        <HStack>
          <CircleChevronRightIcon w={4} h={4} color="#e65550" />
          <Text
            color={isFocus ? "#e65550" : "#666"}
            fontSize="14px"
            textDecoration={isFocus ? "underline" : "none"}
            textDecorationColor="#e65550"
          >
            {sublink.title}
          </Text>
        </HStack>
      </Link>
    </GridItem>
  );
};

export const MegaMenu = ({
  megaLink,
  open,
  close,
}: {
  megaLink: {
    title: string;
    link: string;
    children: { title: string; link: string }[];
  };
  open: boolean;
  close: Function;
}) => (
  <Flex
    style={{
      maxWidth: "1130px",
      width: "100%",
      height: open ? "150px" : "0px",
      transition: "all 0.3s ease",
    }}
  >
    <VStack w="100%" pt="20px" display={open ? "block" : "none"}>
      <HStack w="100%">
        <CircleChevronRightIcon w={4} h={4} color="#e65550" />
        <Link to={megaLink.link}>
          <Text color="#777" fontSize="20px" fontWeight="bold" cursor="pointer">
            {megaLink.title}
          </Text>
        </Link>
        <Spacer />
        <CloseIcon
          w={4}
          h={4}
          color="#666"
          onClick={() => close()}
          cursor="pointer"
        />
      </HStack>
      <Box
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#CCC",
          marginBottom: "10px",
        }}
      ></Box>
      <HStack w="100%" marginTop="100px">
        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
          {megaLink.children.map((sublink, subIndex) => {
            return (
              <MegaMenuItem
                key={subIndex}
                megaLink={megaLink}
                sublink={sublink}
              />
            );
          })}
        </Grid>
      </HStack>
    </VStack>
  </Flex>
);
