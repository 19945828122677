import { Box, Text, VStack } from "@chakra-ui/react";
import { MainContainer } from "components";
import { ReactNode } from "react";

interface PageProps {
  title: string;
  children: ReactNode;
}

export const PageContainer = ({ children, title }: PageProps) => (
  <MainContainer title={title} hideNavbarAfter={true}>
    <Box
      style={{
        width: "100%",
        backgroundColor: "#e1d7d7",
      }}
    >
      <Box maxW="1130px" margin="0 auto" padding="20px 30px">
        <Text color="#e65550" fontSize="2.5rem" fontWeight="bold">
          {title}
        </Text>
      </Box>
    </Box>
    <Box
      style={{
        width: "100%",
        backgroundColor: "#eeefef",
      }}
    >
      <VStack
        maxW="1130px"
        margin="0 auto"
        padding="60px 30px"
        alignItems="self-start"
      >
        {children}
      </VStack>
    </Box>
  </MainContainer>
);
