import { VStack, Text, Box } from "@chakra-ui/react";

export const ContentTitle = ({ children }: { children: string }) => (
  <VStack pb="30px" w="100%" alignItems="flex-start">
    <Text color="#4d4d4d" fontSize="1.5rem" fontWeight="500">
      {children}
    </Text>
    <Box h="2px" w="100%" bgColor="#dedede" mb="30px">
      <Box h="2px" w="60px" bgColor="#e65550"></Box>
    </Box>
  </VStack>
);
