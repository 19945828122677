import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  CircleChevronRightIcon,
  getLinks,
  HeaderBackgroundSmall,
  RegionWhite,
} from "components";
import { Link } from "react-router-dom";

const DrawerLink = ({
  link,
  parent,
  isSubLink,
}: {
  link: any;
  parent: any | null;
  isSubLink: boolean;
}) => {
  return (
    <Link
      to={`${parent ? parent.link : ""}${link.link}`}
      style={{ width: "100%" }}
    >
      <HStack
        w="100%"
        borderBottom={isSubLink ? "1px solid #e2d8d3" : "1px solid #f6f3f2"}
        p="8px 15px 8px 15px"
        cursor="pointer"
        justifyContent="space-between"
        marginTop="0px !important"
      >
        <Text
          color={isSubLink ? "#666" : "#666"}
          fontSize={isSubLink ? "14px" : "15px"}
        >
          {link.title}
        </Text>
        <CircleChevronRightIcon color="#e65550" fontSize={15} />
      </HStack>
    </Link>
  );
};

const DrawerOpenLink = ({ link }: { link: any }) => {
  return (
    <VStack w="100%">
      <HStack
        w="100%"
        borderBottom="1px solid white"
        p="3px 15px 8px 15px"
        cursor="pointer"
        justifyContent="space-between"
      >
        <Text color="#666" fontSize="15px">
          {link.title}
        </Text>
        <Box position="relative">
          <Text
            color="#e65550"
            fontSize={25}
            h="20px"
            top="-20px"
            right="0"
            position="absolute"
          >
            +
          </Text>
        </Box>
      </HStack>
      <VStack bg="#f6f3f2" w="100%" marginTop="0px !important">
        <DrawerLink parent={null} link={link} isSubLink={true} />
        {link.children.map((child: any, index: number) => {
          return (
            <DrawerLink
              parent={link}
              link={child}
              key={index}
              isSubLink={true}
            />
          );
        })}
      </VStack>
    </VStack>
  );
};

export const DrawerMenu = ({
  open,
  close,
  btnRef,
}: {
  open: boolean;
  close: any;
  btnRef: any;
}) => {
  const Links = getLinks();
  return (
    <Drawer
      isOpen={open}
      placement="right"
      onClose={close}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent style={{ background: "#e2d8d3" }}>
        <VStack>
          <VStack bg="#e65550" padding="25px 0" w="100%">
            <HStack w="100%" zIndex={1}>
              <Spacer />
              <HStack
                align={"end"}
                display="flex"
                p="10px"
                cursor="pointer"
                onClick={() => close()}
                alignItems="center"
              >
                <CloseIcon w={4} h={4} color="white" />
              </HStack>
            </HStack>
            <HStack justifyContent="space-around" w="60%" pt="30px">
              <VStack spacing={1} cursor="pointer">
                <RegionWhite width="40px" height="40px" />
                <Text fontSize="0.8rem" color="white">
                  Global
                </Text>
              </VStack>
              {/* <VStack spacing={1} cursor="pointer">
                <Image
                  src="https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  alt="Global"
                  w="40px"
                  h="40px"
                  borderRadius="50%"
                />
                <Text fontSize="0.8rem" color="white">
                  Dulguun
                </Text>
              </VStack> */}
            </HStack>
          </VStack>
          {Links.map((link, index) => {
            return link.children ? (
              <DrawerOpenLink link={link} key={index} />
            ) : (
              <DrawerLink
                parent={null}
                link={link}
                key={index}
                isSubLink={false}
              />
            );
          })}
        </VStack>
        <HeaderBackgroundSmall
          style={{ position: "absolute", width: "100%", height: "80px" }}
        />
      </DrawerContent>
    </Drawer>
  );
};
