import { Box, Text } from "@chakra-ui/react";
import { CarouselMenu, MainContainer, subLinks } from "components";

export function About() {
  const Links = subLinks(0);
  return (
    <MainContainer title={Links.title}>
      <Box
        style={{
          width: "100%",
          background: `url(/assets/about-head.jpeg) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "0 30px",
        }}
      >
        <Text
          style={{
            color: "#4d4d4d",
            paddingTop: "310px",
            paddingBottom: "85px",
            fontSize: "2.5rem",
            fontWeight: "bold",
            maxWidth: "1130px",
            margin: "0 auto",
          }}
        >
          {Links.title}
        </Text>
      </Box>
      <CarouselMenu menus={Links.children} prefix={Links.link} />
    </MainContainer>
  );
}
