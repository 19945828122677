import { Box, Flex, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import { Logo } from "./svg";

export const Footer = () => (
  <VStack>
    <VStack
      style={{
        width: "100%",
        backgroundColor: "#e65550",
        padding: "130px 0 43px",
      }}
    >
      <Text
        style={{
          color: "white",
          fontSize: "2.2rem",
          width: "50%",
          textAlign: "center",
        }}
      >
        Бяцхан зүрх бүхний төлөө
      </Text>
      <HStack gap={4} pt={4}>
        <a
          href="https://www.facebook.com/pigeonmongolia"
          target="_blank"
          rel="noreferrer"
        >
          <Text
            style={{
              color: "white",
              fontSize: "1rem",
            }}
          >
            Facebook
          </Text>
        </a>
        <a
          href="https://www.instagram.com/pigeon_mongolia"
          target="_blank"
          rel="noreferrer"
        >
          <Text
            style={{
              color: "white",
              fontSize: "1rem",
            }}
          >
            Instagram
          </Text>
        </a>
        <a
          href="https://www.youtube.com/channel/UCKo_UZNPwpgLO9tn6S1K6tQ"
          target="_blank"
          rel="noreferrer"
        >
          <Text
            style={{
              color: "white",
              fontSize: "1rem",
            }}
          >
            Youtube
          </Text>
        </a>
      </HStack>
    </VStack>
    <VStack
      bgSize={{
        base: "100% 60px",
        md: "100% 80px",
        lg: "100% 100px",
        xl: "100% 120px",
      }}
      bg="url(/assets/bg_footer_wave_pc.jpeg) center"
      style={{
        padding: "70px 80px 50px",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        marginTop: 0,
        width: "100%",
      }}
    >
      <Flex
        width="100%"
        paddingTop={{ base: "80px", md: "70px", lg: "20px" }}
        flexDirection={{ base: "column", md: "column", lg: "row" }}
        alignItems="center"
      >
        <Box
          width={{ base: "100px", md: "140px", lg: "180px" }}
          transition="0.5s"
        >
          <Logo style={{ width: "100%" }} />
        </Box>
        <Text
          paddingTop={{ md: "15px", lg: 0 }}
          style={{
            marginLeft: "20px",
            color: "#4d4d4d",
            fontSize: "1.1rem",
            marginTop: "0.2rem",
          }}
        >
          MONGOLIA
        </Text>
      </Flex>
      <Flex
        width="100%"
        paddingTop="35px"
        flexDirection={{
          base: "column",
          md: "column",
          lg: "column",
          xl: "row",
        }}
      >
        {/* <Text
          style={{
            color: "#4d4d4d",
            fontSize: "1.0rem",
          }}
        >
          Sitemap
        </Text>
        <Text
          style={{
            color: "#4d4d4d",
            fontSize: "1.0rem",
          }}
        >
          Subsidiaries
        </Text>
        <Text
          style={{
            color: "#4d4d4d",
            fontSize: "1.0rem",
          }}
        >
          Disclosure
        </Text>
        <Text
          style={{
            color: "#4d4d4d",
            fontSize: "1.0rem",
          }}
        >
          Policy
        </Text>
        <Text
          style={{
            color: "#4d4d4d",
            fontSize: "1.0rem",
          }}
        >
          Policy on Privacy Protection
        </Text>
        <Text
          style={{
            color: "#4d4d4d",
            fontSize: "1.0rem",
          }}
        >
          Cookie Policy
        </Text>
        <Text
          style={{
            color: "#4d4d4d",
            fontSize: "1.0rem",
          }}
        >
          Social Media Policy
        </Text>
        <Text
          style={{
            color: "#4d4d4d",
            fontSize: "1.0rem",
          }}
        >
          Terms of Use
        </Text> */}
        <Spacer />
        <Text
          mt={{ base: 10, md: 10, lg: 0, xl: 0 }}
          style={{
            color: "#4d4d4d",
            fontSize: "0.9rem",
            textAlign: "end",
          }}
        >
          Copyright © Pigeon Corporation All Rights Reserved.
        </Text>
      </Flex>
    </VStack>
  </VStack>
);
