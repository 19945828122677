import { ChakraProvider } from "@chakra-ui/react";
import { ScrollToTop } from "components";
import {
  Abico,
  About,
  Advice,
  AdviceCategory,
  AdviceHome,
  AdviceProducts,
  Category,
  CategoryProducts,
  Detailed,
  Ethics,
  Logo,
  News,
  NewsDetail,
  Product,
  Welcome,
} from "pages";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <ChakraProvider>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/ethics" element={<Ethics />} />
          <Route path="/about/abico" element={<Abico />} />
          <Route path="/about/detailed" element={<Detailed />} />
          <Route path="/about/logo" element={<Logo />} />
          <Route path="/category" element={<Category />} />
          <Route path="/category/:id" element={<CategoryProducts />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/advice-home" element={<AdviceHome />} />
          <Route path="/advice-products/:id" element={<AdviceProducts />} />
          <Route path="/advice-category/:id" element={<AdviceCategory />} />
          <Route path="/advice/:id" element={<Advice />} />
        </Routes>
      </ScrollToTop>
    </ChakraProvider>
  );
}

export default App;
