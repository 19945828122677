import { Box, HStack, Spacer, Text, VStack, Image } from "@chakra-ui/react";
import api from "api";
import { ContentTitle, MainContainer } from "components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function NewsDetail() {
  let { id } = useParams();

  const [news, setNews] = useState<{
    category: string;
    date_created: string;
    news_id: string;
    image: string;
    title: string;
    description: string;
    gallery: string[];
  } | null>(null);

  useEffect(() => {
    if (id) {
      api.news.get(id).then((result) => {
        setNews(result.data);
      });
    }
  }, [id]);

  return (
    <MainContainer title="Мэдээ, мэдээлэл">
      <Box
        style={{
          width: "100%",
          background: `url(/assets/news-head.jpeg) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "0 30px",
        }}
      >
        <Text
          style={{
            color: "#4d4d4d",
            paddingTop: "310px",
            paddingBottom: "85px",
            fontSize: "2.5rem",
            fontWeight: "bold",
            maxWidth: "1130px",
            margin: "0 auto",
          }}
        >
          Мэдээ, мэдээлэл
        </Text>
      </Box>
      <Box
        style={{
          width: "100%",
          backgroundColor: "#eeefef",
        }}
      >
        <VStack
          maxW="1130px"
          margin="0 auto"
          padding="60px 30px"
          alignItems="self-start"
          gap={4}
        >
          <ContentTitle>{news?.title!}</ContentTitle>
          <HStack w="100%">
            <Spacer />
            <Text color="#4d4d4d">{news?.date_created}</Text>
          </HStack>

          <Image src={`/uploads/news/${news?.image}`} />

          <div
            style={{ color: "#4d4d4d" }}
            dangerouslySetInnerHTML={{ __html: news?.description! }}
          />
          {news?.gallery?.map((image, index) => (
            <Image key={`image-${index}`} src={`/uploads/news/${image}`} />
          ))}
        </VStack>
      </Box>
    </MainContainer>
  );
}
