const Links = [
  {
    title: "Компанийн тухай",
    link: "/about",
    image: "/assets/menu/bg_menu07.jpg",
    children: [
      {
        title: "Нэр, логоний гарал үүсэл",
        link: "/logo",
        image: "/assets/menu/p_histor_thumb.jpeg",
      },
      {
        title: "Ёс зүй",
        link: "/ethics",
        image: "/assets/menu/p_policies_thumb.jpeg",
      },
      {
        title: "Дэлгэрэнгүй танилцуулга",
        link: "/detailed",
        image: "/assets/menu/p_companyinfo_thumb.jpeg",
      },
      {
        title: "Монгол дахь борлуулагч Абико",
        link: "/abico",
        image: "/assets/menu/p_statement_thumb_pc.jpeg",
      },
    ],
  },
  {
    title: "Бүтээгдэхүүн",
    link: "/category",
    image: "/assets/menu/bg_menu05.jpg",
  },
  {
    title: "Зөвлөгөө",
    link: "/advice-home",
    image: "/assets/menu/bg_menu08.jpg",
  },
  {
    title: "Мэдээлэл",
    link: "/news",
    image: "/assets/menu/bg_menu06.jpg",
  },
];

export const getLinks = () => {
  return Links;
};

export const subLinks = (index: number) => {
  return Links[index];
};
