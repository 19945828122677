import { Box } from "@chakra-ui/react";
import { Footer, Navbar } from "components";
import { ReactNode } from "react";

interface PageProps {
  title?: string;
  hideNavbarAfter?: boolean;
  children: ReactNode;
}

export const MainContainer = ({
  title,
  hideNavbarAfter = false,
  children,
}: PageProps) => {
  document.title = `${title ? `${title} |` : ""} Pigeon Mongolia`;
  return (
    <Box maxW="1920px" margin="0 auto">
      <Navbar hideAfter={hideNavbarAfter} />
      {children}
      <Footer />
    </Box>
  );
};
