import { Box, Grid, Text } from "@chakra-ui/react";
import {
  adviceCategoryMockData,
  CategoryProductItem,
  MainContainer,
  productMockData,
} from "components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export function AdviceProducts() {
  let { id } = useParams();

  const [adviceCategory, setAdviceCategory] = useState<{
    id: number;
    title: string;
  } | null>(null);
  console.log(id);
  const [products, setProducts] = useState<
    | {
        id: string;
        thumb: string;
        title: string;
      }[]
  >([]);

  useEffect(() => {
    if (id) {
      adviceCategoryMockData.forEach((advice) => {
        if (advice.id.toString() === id) {
          setAdviceCategory(advice);
        }
      });
      setProducts(productMockData.filter((p) => p.article === id));
    }
  }, [id]);

  return (
    <MainContainer title="Бүтээгдэхүүн">
      <Box
        style={{
          width: "100%",
          background: `url(/assets/category-head.jpeg) center`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          padding: "0 30px",
        }}
      >
        <Text
          style={{
            color: "#4d4d4d",
            paddingTop: "310px",
            paddingBottom: "85px",
            fontSize: "2.5rem",
            fontWeight: "bold",
            maxWidth: "1130px",
            margin: "0 auto",
          }}
        >
          {adviceCategory?.title}
        </Text>
      </Box>
      <Grid
        maxW="1130px"
        margin="0 auto"
        p={4}
        templateColumns={[
          "repeat(2, 1fr)",
          "repeat(2, 1fr)",
          "repeat(3, 1fr)",
          "repeat(4, 1fr)",
        ]}
        gap={4}
      >
        {products.map((product, index) => (
          <CategoryProductItem key={index} product={product} />
        ))}
      </Grid>
    </MainContainer>
  );
}
