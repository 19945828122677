import { createIcon } from "@chakra-ui/icons";

export const CircleChevronRightIcon = createIcon({
  displayName: "circle-chevron-right",
  viewBox: "0 0 512 512",
  path: (
    <path
      fill="currentColor"
      d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM358.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L290.8 256L201.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C364.9 239.6 368 247.8 368 256S364.9 272.4 358.6 278.6z"
    />
  ),
});

export const UserIcon = createIcon({
  displayName: "circle-chevron-right",
  viewBox: "0 0 448 512",
  path: (
    <path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z" />
  ),
});

export const ShopIcon = createIcon({
  displayName: "circle-chevron-right",
  viewBox: "0 0 28 28",
  path: (
    <g>
      <g>
        <path
          d="M5.5,6.5H23.14a2,2,0,0,1,2,2.33l-1.46,6.85a1,1,0,0,1-1,.82H8"
          style={{
            fill: "none",
            strokeMiterlimit: 10,
            strokeWidth: "0.708661417322835px",
          }}
          stroke="currentColor"
        />
        <circle
          cx="10.5"
          cy="23.5"
          r="2"
          style={{
            fill: "none",
            strokeMiterlimit: 10,
            strokeWidth: "0.708661417322835px",
          }}
          stroke="currentColor"
        />
        <circle
          cx="21.5"
          cy="23.5"
          r="2"
          style={{
            fill: "none",
            strokeMiterlimit: 10,
            strokeWidth: "0.708661417322835px",
          }}
          stroke="currentColor"
        />
        <path
          d="M2.5,2.5c2,0,2,1,3,4l3,12c.1.46.34,1,.81,1H23.5"
          style={{
            fill: "none",
            strokeMiterlimit: 10,
            strokeWidth: "0.708661417322835px",
          }}
          stroke="currentColor"
        />
      </g>
      <rect
        width="28"
        height="28"
        style={{
          fill: "none",
        }}
      />
    </g>
  ),
});
