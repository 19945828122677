import { Box, Text, VStack, Image, HStack } from "@chakra-ui/react";
import {
  adviceCategoryMockData,
  advicesMockData,
  CircleChevronRightIcon,
  MainContainer,
} from "components";
import { Link } from "react-router-dom";

const SubItem = ({
  categoryId,
  items,
}: {
  categoryId: number;
  items: { id: number; title: string }[];
}) => {
  return (
    <VStack>
      {items.map((item, index) => {
        let url = `/advice-category/${item.id}`;
        const tempAdvices: {
          id_article: number;
          id_article_category: number;
          id_age: number;
          title: string;
          youtube_url: string;
          description: string;
          image: string;
        }[] = [];
        advicesMockData.forEach((advice) => {
          if (advice.id_article_category === item.id) {
            tempAdvices.push(advice);
          }
        });
        if (tempAdvices.length === 1) {
          url = `/advice/${tempAdvices[0].id_article}`;
        }
        return (
          <Link to={url} style={{ width: "100%" }} key={`link-${index}`}>
            <HStack
              style={{
                background: "white",
                width: "100%",
                justifyContent: "space-between",
                color: "#d44e8d",
                padding: "5px 15px",
                fontSize: "14px",
                borderRadius: "5px",
              }}
            >
              <Text>{item.title}</Text>
              <CircleChevronRightIcon
                mr={1}
                mb="5px"
                w={4}
                h={4}
                color="#d44e8d"
              />
            </HStack>
          </Link>
        );
      })}
      <Link to={`/advice-products/${categoryId}`} style={{ width: "100%" }}>
        <HStack
          style={{
            background: "white",
            width: "100%",
            justifyContent: "space-between",
            color: "#d44e8d",
            padding: "5px 15px",
            fontSize: "14px",
            borderRadius: "5px",
          }}
        >
          <Text as="i">Холбоотой бүтээгдэхүүнүүд</Text>
          <CircleChevronRightIcon mr={1} mb="5px" w={4} h={4} color="#d44e8d" />
        </HStack>
      </Link>
    </VStack>
  );
};

const CategoryItem = ({
  category,
  color,
}: {
  color: string;
  category: {
    id: number;
    title: string;
    image: string;
    children: { id: number; title: string }[];
  };
}) => {
  return (
    <VStack w="100%" spacing={2}>
      <Text
        style={{
          background: color,
          width: "100%",
          padding: 15,
          borderRadius: 5,
          textAlign: "center",
          color: "white",
          fontWeight: "bold",
          position: "relative",
        }}
        _after={{
          content: '""',
          position: "absolute",
          top: "100%",
          left: "50%",
          borderWidth: "8px 5px",
          borderTopColor: color,
          borderLeftColor: "transparent",
          borderRightColor: "transparent",
          borderBottomColor: "transparent",
        }}
      >
        {category.title}
      </Text>
      <Box
        style={{
          borderRadius: 5,
          overflow: "hidden",
          width: "100%",
          position: "relative",
        }}
      >
        <Image width="100%" src={`/assets/advice/${category.image}`} />
        <Box
          display={["none", "none", "block", "block"]}
          style={{
            position: "absolute",
            top: "10%",
            right: "2%",
            width: "40%",
          }}
        >
          <SubItem categoryId={category.id} items={category.children}></SubItem>
        </Box>
      </Box>
      <Box
        display={["block", "block", "none", "none"]}
        style={{
          borderRadius: 5,
          overflow: "hidden",
          width: "100%",
          background: color,
          padding: "5px",
        }}
      >
        <SubItem categoryId={category.id} items={category.children}></SubItem>
      </Box>
    </VStack>
  );
};

export function AdviceHome() {
  const colors = ["#b480c1", "#fc9698", "#a6de81", "#e9ce52", "#93d7e8"];
  return (
    <MainContainer title="Зөвлөгөө" hideNavbarAfter>
      <VStack maxW="1130px" margin="0 auto" p={4} gap={4}>
        <Image src="/assets/advice/advice_header.jpg" marginY={2} />
        {adviceCategoryMockData.map((category, index) => (
          <CategoryItem
            key={index}
            category={category}
            color={colors[index % 5]}
          />
        ))}
      </VStack>
    </MainContainer>
  );
}
