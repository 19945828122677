import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  VStack,
} from "@chakra-ui/react";

export const AboutTable = ({
  data,
}: {
  data: { title: string; desc: string[] }[];
}) => (
  <TableContainer
    width="100%"
    style={{
      border: "1px solid #bebebe",
      borderRadius: "10px",
    }}
  >
    <Table variant="simple" color="#4d4d4d">
      <Tbody>
        {data.map((row, i) => (
          <Tr key={i}>
            <Th
              bgColor="#e1d7d7"
              borderBottom="1px solid #bebebe"
              color="#4d4d4d"
            >
              {row.title}
            </Th>
            <Td>
              <VStack alignItems="flex-start">
                {row.desc.map((text, j) => (
                  <Text
                    key={j}
                    style={{
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    {text}
                  </Text>
                ))}
              </VStack>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  </TableContainer>
);
