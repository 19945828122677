import { AspectRatio, Box, GridItem, Text, VStack } from "@chakra-ui/react";
import { CircleChevronRightIcon } from "components";
import { useState } from "react";
import { Link } from "react-router-dom";

export const CategoryProductItem = ({
  product,
}: {
  product: {
    id: string;
    thumb: string;
    title: string;
  };
}) => {
  const [focused, setFocused] = useState(false);
  return (
    <GridItem
      cursor="pointer"
      onMouseOver={() => setFocused(true)}
      onMouseOut={() => setFocused(false)}
    >
      <Link to={`/product/${product.id}`}>
        <AspectRatio ratio={1 / 1.1} border="solid 1px #EEE" borderRadius="4px">
          <VStack w="100%" h="100%" spacing={0}>
            <Box
              style={{
                flex: 1,
                width: "100%",
                position: "relative",
                background: `url(/uploads/products/${product.thumb}) center`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <Box
                style={{
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  backgroundColor: "#fff",
                  transition: "all .5s ease-out",
                  opacity: focused ? 0.45 : 0,
                }}
              ></Box>
            </Box>
            <Box w="100%" h="50px" p={1.5}>
              <Text color="#4d4d4d" fontSize="14px">
                {product.title}
                <CircleChevronRightIcon
                  ml={2}
                  mb="5px"
                  w={4}
                  h={4}
                  color="#e65550"
                />
              </Text>
            </Box>
          </VStack>
        </AspectRatio>
      </Link>
    </GridItem>
  );
};
